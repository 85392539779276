<template>
  <section class="sub-page iframe">
    <!-- herosection -->
    <div class="relative mt-20 mb-20">
      <img
        src="/theme-assets/images/dashboardImg.png"
        alt=""
        class="h-[260px] w-full"
      />
      <div
        class="absolute md:top-[100px] top-20 left-[15%] md:left-[15%] right-[15%] md:right-[15%] text-center"
      >
        <div class="py-10 px-10 md:px-20 bg-[#262637]">
          <p style="color: #1cfa78" class="text-[#1cfa78] text-4xl">
            Find your Home
          </p>
          <p class="text-[#8c8c92]">
            Find suitable properties that meet your budget
          </p>
          <div class="grid md:grid-cols-5 md:gap-2 mt-8 gap-4">
            <select
              id="states"
              v-model="form.state"
              name="states"
              class="w-full rounded-sm p-2"
            >
              <option disabled value="">Select a state</option>

              <option v-for="(state, i) in states" :key="i" :value="state">
                {{ state.stateName }}
              </option>
            </select>

            <select
              id="Beds"
              v-model="form.noOfBeds"
              name="noOfBeds"
              class="w-full rounded-sm p-2 md:w-[33%]"
            >
              <option disabled value="">Beds</option>
              <option value="1">I bed</option>
              <option value="2">2 bed</option>
              <option value="3">3 bed</option>
              <option value="4">4 beds</option>
            </select>

            <select
              id="Type of house"
              v-model="form.type"
              name="type"
              class="w-full rounded-sm p-2 md:w-[33%]"
            >
              <option disabled value="">Type of House</option>
              <option value=" 2 Bedroom Semi-Detached Bungalows">
                2 Bedroom Semi-Detached Bungalows
              </option>
              <option value="1 Bedroom Semi-Detached Bungalows">
                1 Bedroom Semi-Detached Bungalows
              </option>
              <option value="3 Bedroom Semi-Detached Bungalows">
                3 Bedroom Semi-Detached Bungalows
              </option>
              <option value="4 Bedroom Semi-Detached Bungalows">
                4 Bedroom Semi-Detached Bungalows
              </option>
              <option value=" 2 Bedroom flat">2 Bedroom flat</option>
            </select>

            <select
              id="price"
              v-model="form.price"
              name="price"
              class="w-full rounded-sm p-2 md:w-[33%]"
            >
              <option disabled value="">Price</option>
              <option value="200000">100000 - 200000</option>
              <option value="300000">200000 - 300000</option>
              <option value="400000">300000 - 400000</option>
              <option value="500000">400000 - 500000</option>
            </select>

            <button
              class="bg-[#1cfa78] text-[#262637] px-4 rounded-sm"
              @click="filterByState()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-2/3 mx-auto text-center gap-8 md:gap-0 grid md:grid-cols-3 mt-48 md:mt-32 mb-8"
    >
      <router-link :to="{ name: 'housing.market' }">
        <div class="flex flex-row justify-center gap-4">
          <p class="text-sm">Connect with developer</p>
          <img
            src="/theme-assets/images/connectDev.png"
            alt=""
            class="h-[44px] w-[44px]"
          />
        </div>
      </router-link>

      <div class="flex items-center justify-center gap-4">
        <p>Checkout Properties</p>
        <img
          src="/theme-assets/images/properties.png"
          alt=""
          class="h-[44px] w-[44px]"
        />
      </div>

      <router-link :to="{ name: 'own.home' }">
        <div class="flex items-center justify-center gap-4">
          <p>Mortgage Eligibility</p>
          <img
            src="/theme-assets/images/mortgage.png"
            alt=""
            class="h-[44px] w-[44px]"
          />
        </div>
      </router-link>
    </div>

    <div v-if="properties.length == 0" class="w-[90%] md:w-[80%] mx-auto">
      <p class="pb-4 text-lg transition-all duration-300 text-center py-20">
        We are still loading properties for
        {{ form?.state?.stateName }} State <br />
        Please check again later.
      </p>
    </div>

    <div class="w-[90%] mx-auto">
      <div v-if="properties.length > 0" class="container mx-auto p-4">
        <b-row>
          <b-col
            v-for="(property, i) in properties"
            :key="i"
            class="flex flex-col hover:bg-gray-200 transition-all duration-300 gap-1 items-start mb-10 p-4"
            cols="12"
            md="6"
            lg="4"
          >
            <div class="w-full h-[200px]">
              <img
                src="/theme-assets/images/property.jpg"
                alt=""
                class="w-full h-full object-cover"
              />
            </div>

            <div class="p-2">
              <span class="text-sm"> N{{ property.price }} </span>
              <br />
              <span class="text-sm"> {{ property.type }} </span><br />
              <span class="text-sm">
                <i class="fa fa-bed" />
                {{ property.noOfBeds }} Beds</span
              ><br />
              <span class="text-sm">
                <i class="ti-location-pin" />
                {{ property.state }} State
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- types of Mortgage -->

    <hr />
    <div
      class="flex text-black justify-between mx-auto items-center text-white md:flex-row flex-col gap-4 w-[90%]"
    >
      <div class="w-full flex flex-col w-[60%]">
        <p class="text-xl font-semibold">
          Mortgage/Housing Finance Options in Nigeria
        </p>
        <p class="text-lg pb-4">
          Buying a house in Nigeria must not always be through outright
          purchase. There are several mortgage/housing finance options
          available. See the options below:
        </p>

        <div class="flex items-start justify-between pb-6">
          <div class="w-[45%]">
            <img src="/theme-assets/images/property.jpg" />
          </div>

          <div class="flex gap-y-2 w-[50%] text-black flex-col">
            <div class="flex items-start flex-col">
              <span class="text-lg font-medium">
                Commercial (NMRC Refinance-able) Mortgage
              </span>
              <p>
                A mortgage is a loan for purchasing a property, using the
                property as collateral. If the borrower can&#39;t make payments,
                the lender may take possession of the property.
              </p>
              <p>
                <span>1. Fixed-rate mortgage</span> <br />
                <span>2. Adjustable-rate mortgage</span><br />
                <span>3. Interest-only mortgage</span><br />
              </p>
            </div>

            <div class="">
              <span class="text-lg font-medium"
                >National Housing Fund (NHF)</span
              >
              <p>
                The National Housing Fund (NHF) is a government scheme in
                Nigeria that allows employed Nigerians over 21 to access low-
                interest loans of up to N50 million. Participants contribute
                2.5% of their monthly salary to the fund and can repay the loan
                over a maximum of 30 years at a 6% interest rate.
              </p>
              <p>
                <span>1. NHF Mortgage Loan Estate Development Loan</span><br />
                <span>2. Home Renovation Loan</span> <br />
                <span>3. Rent to Own</span> <br />
                <span>4. Construction Loan</span><br />
                <span>5. Diaspora NHF Mortgage Loan</span>
              </p>
            </div>

            <div class="flex items-start flex-col">
              <span class="text-lg font-medium"> Family Homes Fund (FHF) </span>
              <p>
                Help to Own is a Home Loans assistance initiative of Family
                Homes Funds
              </p>
              <p><span>Help to Own</span><br /></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Form from "vform";
import states from "./states.json";
import properties from "./properties.json";

export default {
  name: "PropertyInformation",
  components: {},

  data: () => ({
    nav_title: "Property Information",
    navs: [
      {
        route_name: "property.info",
        route_title: "Property Information",
      },
    ],
    visible: true,
    iframe: {
      src: "https://app.powerbi.com/view?r=eyJrIjoiNWVhNTM4OTItNGMwNS00MjIwLWI5ZWUtYWQ4MzQxNmMwNTAyIiwidCI6ImE4MTNjYTk4LWU0MTMtNDJkMS1iNzNmLTlmZGM3YTJhYjhkNCIsImMiOjh9",
      loaded: false,
    },
    states: states,
    properties: properties,
    originalProperties: properties,

    form: new Form({
      state: "",
      price: "",
      type: "",
      noOfBeds: "",
    }),
  }),

  methods: {
    load() {
      this.iframe.loaded = true;
    },
    filterByState() {
      this.properties = this.originalProperties;
      let selectedState = this.form.state.stateName?.toLocaleLowerCase();
      let selectedType = this.form.type;
      let selectedPrice = this.form.price;
      let selectedNoOfBeds = this.form.noOfBeds;

      if (selectedState.length) {
        this.properties = this.properties.filter(
          (x) => x.state?.toLocaleLowerCase() == selectedState
        );
      }

      if (selectedType.length) {
        this.properties = this.properties.filter((x) => x.type == selectedType);
      }
      if (selectedPrice.length) {
        this.properties = this.properties.filter((x) => {
          return x.price == selectedPrice;
        });
      }
      if (selectedNoOfBeds.length) {
        this.properties = this.properties.filter(
          (x) => x.noOfBeds == selectedNoOfBeds
        );
      }
    },
  },
};
</script>
<style scoped>
.iframe {
  overflow-y: hidden;
}
</style>
